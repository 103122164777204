<template>
	<div>
		<div class="row">
			<div class="col-4 form-group">
        		<label class="col-form-label">{{ $t('monte.stallion') }}</label>
                <e-select
                    v-model="stallions_selected"
                    id="stallion"
                    track-by="horse_id"
                    label="horse_nom"
                    :placeholder="$t('monte.selectionnez_etalon')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="horses"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :multiple="true"
					:loading="loadingHorse"
                />
            </div>

            <div class="col-4 form-group">
        		<label for="tiers" class="col-form-label">{{ $t("monte.stock_semence.vers_tiers") }}</label>
				<e-select
					v-model="tiers"
				    id="tiers"
				    track-by="tiers_rs"
				    label="tiers_rs"
				    :placeholder="$t('tiers.search_tiers')"
				    :selectedLabel="$t('global.selected_label')"
				    :options="all_tiers"
				    :searchable="true"
				    :show-labels="false"
					:loading="loadingTiers"
				/>
            </div>

            <div class="col-4 form-group">
        		<label class="col-form-label">{{ $t("monte.stock_semence.date_sortie") }}</label>
				<e-datepicker v-model="date_sortie"></e-datepicker>
            </div>
		</div>

		<div v-if="reloadStock">
			<div v-for="(stallion, id) in stocks" :key="id" class="border-bottom pb-2 mt-4">
				<div class="row mb-2">
					<div class="col">
						<h3>{{ stallion.name }}</h3>
					</div>
					<div class="col">
						<e-select
                    		v-model="stallion.lot"
		                    track-by="lot"
		                    label="label"
		                    :placeholder="$t('monte.stock_semence.selectionnez_lot')"
		                    :selectedLabel="$t('global.selected_label')"
		                    :options="stallion.stock"
		                    :searchable="true"
		                    :allow-empty="true"
		                    :show-labels="false"
		                    :multiple="true"
							@input="resfreshStock"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col-4 mt-2" v-for="lot in stallion.lot" :key="lot.uid">
						<b-input-group :prepend="lot.lot">
							<b-form-input
								type="number"
								v-model="lot.qte"
								min="0"
								:max="lot.dispo"
							>
							</b-form-input>
						</b-input-group>
					</div>
				</div>
			</div>
		</div>
			
		<div>
			<div class="col-12 text-center mt-3">
	        	<button @click="submitForm" class="btn btn-primary rounded-pill">
	        		<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
	                <font-awesome-icon v-else :icon="['far', 'save']" /> {{ $t('global.valider') }}
	            </button>
	        </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
import Shutter from '@/mixins/Shutter'
import ShutterSemence from '@/mixins/shutters-manager/Semence'
import StockSemence from '@/mixins/StockSemence'
import Tiers from '@/mixins/Tiers'
import _groupBy from 'lodash/groupBy'

	export default {
		name: 'SortieStock',
		mixins: [Shutter, ShutterSemence, StockSemence, Tiers],
		props: ['horses'],
		data () {
			return {
				all_couleurs: [],
				all_localisations: [],
				all_tiers: [],
				loadingHorse: false,
				loadingTiers: false,
				stallions_selected: [],
				tiers: null,
				date_sortie: new Date(),
				stocks: {},
				reloadStock: true,
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loadingTiers = true
				this.all_tiers = await this.loadTiers()
				this.loadingTiers = false

				this.all_couleurs = await this.getAllSemenceCouleur()
				this.all_localisations = await this.getLocalisationSemence()
			},

			resfreshStock() {
				this.reloadStock = false
				this.reloadStock = true
			},

			async submitForm() {
				this.processing = true

				let stock_to_send = []
				for(let stallion in this.stocks) {
					this.stocks[stallion].stock.forEach(stock => {
						if(Object.prototype.hasOwnProperty.call(stock, 'qte') && stock.qte > 0) {
							stock_to_send.push({
								stock: stock.qte,
								date: this.date_sortie,
								to: this.tiers ? this.tiers.tiers_id : null,
								id: stock.uid
							})
						}
					})
				}

				await this.sortieStockSemence(stock_to_send)

				this.processing = false

				this.ok()
				this.shutterPanel().close('sortie-stock')
			},

			async addStallion(horse) {
				this.loadingHorse = true
				const stock = await this.loadAllStockLocalDetailsByStallion(horse.horse_id, true)
				this.loadingHorse = false

				let stocks = []
				const stocks_grouped = _groupBy(stock, 'lot')
				for(let i in stocks_grouped) {
					stocks.push({
						...stocks_grouped[i][0],
						label: stocks_grouped[i][0].label + ' - ' + stocks_grouped[i][0].localisation + ' - ' + stocks_grouped[i][0].couleur,
						dispo: stocks_grouped[i].map(j => j.dispo).reduce((sum, a) => sum + a, 0),
					})
				}

				this.stocks["horse_"+horse.horse_id] = {
					name: horse.horse_nom,
					id: horse.horse_id,
					stock: stocks,
					lot: []
				}

				this.resfreshStock()
			}
		},

		watch: {
			stallions_selected(newV, oldV) {
				let new_stallion = newV.filter(x => !oldV.includes(x))[0]
	
				if(!new_stallion) {
					let delete_stallion = oldV.filter(x => !newV.includes(x))[0]
					delete this.stocks["horse_"+delete_stallion.horse_id]
				}
				else {
					this.addStallion(new_stallion)
				}
			}
		}
	}

</script>